import http from 'api/http';
import { appointmentDomain } from 'models';
import {
  AppointmentIdRequest,
  AppointmentResponse,
  PsrResponse,
  SiteAbortDetails
} from 'models/Appointment';
import { createUrl, commonErrorHandling } from 'store/utils';

export async function fetchPreviousAborts(
  appointmentData: AppointmentResponse
): Promise<SiteAbortDetails | null> {
  const details = appointmentData.appointmentType?.details;
  const mpan = details?.mpan ?? null;
  const mprn = details && 'mprn' in details ? details.mprn : null;

  if ((mpan ?? mprn) === null) return null;

  try {
    const { data: previousAbortData } = await http.post<SiteAbortDetails[]>(
      `${createUrl(appointmentDomain)}/abort/search`,
      { sites: [{ mpan, mprn }] }
    );
    return previousAbortData ? previousAbortData[0] : null;
  } catch (error) {
    commonErrorHandling(error, appointmentDomain.type);
    return null;
  }
}

export async function fetchPsrDetails(
  appointmentIds: AppointmentIdRequest,
  enableNewJobAlertsFetching?: boolean
): Promise<PsrResponse | null> {
  if (!enableNewJobAlertsFetching) {
    return null;
  }
  try {
    const { data: psrDetails } = await http.post<PsrResponse>(
      `${createUrl(appointmentDomain)}/psr/ids`,
      appointmentIds
    );

    return psrDetails;
  } catch (error) {
    commonErrorHandling(error, appointmentDomain.type);

    return null;
  }
}
