import { DateTime } from 'luxon';
import GenericDataType from './GenericDataType';

export interface AppointmentEngineer {
  id: string;
  firstName?: string;
  lastName?: string;
}

export default interface Appointment extends GenericDataType {
  address?: Address;
  abortDetails?: AbortDetails;
  atRisk: boolean;
  status: AppointmentStatus;
  category: AppointmentCategory;
  notes: Note[];
  slot: AppointmentSlot;
  engineers: AppointmentEngineer[];
  // customer booking specific
  jobType?: string;
  patch?: string;
  appointmentType?: AppointmentType;
  complaints: Complaint[];
  brand?: Brand;
  customerDetails?: ContactDetails;
  contactDetails?: ContactDetails;
  location: { lat: number; long: number };
  duration: number;
  accountNumber: string | null;
  bookingReference: string;
  dataOptIn: string | null;
  id: string;
  nonce: string | null;
  reason: string | null;
}

export interface AppointmentResponse extends Omit<Appointment, 'engineers'> {
  engineers: string[];
}

export interface Address {
  addressLineOne: string;
  addressLineTwo: string | null;
  addressLineThree: string | null;
  city: string;
  postcode: string;
}

export interface AppointmentType {
  fuelType?: string | null;
  paymentType?: string;
  details:
    | CustomerAppointmentDetails
    | MaintenanceDetails
    | EVAppointmentDetails;
  type: CustomerAppointmentType;
}

export interface MeterTechnicalDetails {
  metersToBeRemoved: ElectricMeterTechnicalDetail[];
  xvali: {
    importMPAN?: string;
    exportMPAN?: string;
    secondaryImportMPAN?: string;
    mopId?: string;
    importMPRN?: string;
    mamId?: string;
    commsHubType?: string;
    signalStrength?: string;
    auxiliaryEquipmentRequired?: string;
    cspRegion?: string;
  };
  oldGasMsn: string;
  oldElecMsn: string;
}

interface MeterTechnicalDetailType {
  type: MeterType;
}

export interface ElectricMeterTechnicalDetail extends MeterTechnicalDetailType {
  mpan: string | null;
  serialNumber: string;
}

export interface GasMeterTechnicalDetail extends MeterTechnicalDetailType {
  serialNumber: string;
}

export interface CustomerAppointmentDetails {
  ihdRequested: boolean;
  mprn: string;
  mpan: string;
  secondaryMpan?: string | null;
  hasConcealedMeter: boolean;
  meterTechnicalDetails?: MeterTechnicalDetails;
  elecTariff?: string;
}

export interface EVAppointmentDetails {
  mpan: string;
  channel: EVFormChannel;
  zohoAccountId?: string;
  jumptechProjectId?: string;
}

export interface MaintenanceDetails {
  isEmergency: boolean;
  mprn: string | null;
  mpan: string | null;
  jobCode: string | null;
  jobDescription: string | null;
  oldElecMsn: string | null;
  oldSsc: string | null;
  oldCtRatio: string | null;
  oldElecMeterLocation: string | null;
  oldMeterType: string | null;
  oldGasMsn: string | null;
  oldGasMeterLocation: string | null;
  newSsc: string | null;
  storageUnderfloorHeating: string | null;
  newMeterType: string | null;
  newPaymentType: string | null;
  meterMode: string | null;
  energisationStatus: string | null;
  meterPositioning: string | null;
  pressureTier: string | null;
  elecTariff: string | null;
}

export interface ContactDetails {
  title: string;
  firstName: string;
  lastName: string;
  passphrase?: string;
  primaryPhoneNumber: string;
  secondaryPhoneNumber?: string;
  emailAddress?: string;
  vulnerability?: CustomerVulnerability;
}

export interface CustomerVulnerability {
  isOver65: boolean;
  hasDisability: boolean;
  hasHearingDifficulties: boolean;
  hasSpeechDifficulties: boolean;
  hasSmellDifficulties: boolean;
  isBlindOrPartiallySighted: boolean;
  hasSeriousIllness: boolean;
  speaksOtherLanguage: boolean;
  isReliantOnMedicalEquipment: boolean;
  hasYoungChildren: boolean;
  other: string;
}

export interface AbortDetails {
  category: string;
  reason: string;
  comments: string | null;
  rebook: boolean | null;
}

export interface AppointmentAbortDetails {
  appointmentId: string;
  appointmentDate: string;
  abortDetails: AbortDetails;
}

export interface Site {
  mpan: string | null;
  mprn: string | null;
}

export interface SiteAbortDetails {
  site: Site;
  aborts: AppointmentAbortDetails[];
}

export enum BookingChannel {
  AEGIS = 'AEGIS'
}

export enum CustomerAppointmentType {
  SMETS1 = 'SMETS1',
  SMETS2 = 'SMETS2',
  MAINTENANCE = 'MAINTENANCE',
  EV = 'EV'
}

export enum Brand {
  OVO = 'OVO',
  BOOST = 'BOOST'
}

// This is the way appointment statuses come from the API
export enum AppointmentStatus {
  ACTIVE = 'ACTIVE',
  CANCELLED = 'CANCELLED',
  PENDING = 'PENDING',
  ON_ROUTE = 'ON_ROUTE',
  ARRIVED = 'ARRIVED',
  PAUSED = 'PAUSED',
  COMPLETED = 'COMPLETED',
  ABORTED = 'ABORTED'
}

// This is the way we show appointment statuses in the UI
export enum UIAppointmentStatus {
  CANCELLED = 'Cancelled',
  BOOKED = 'Booked',
  IN_PROGRESS = 'In progress',
  COMPLETED = 'Completed',
  ABORTED = 'Aborted',
  EN_ROUTE = 'Еn route'
}

export enum EVFormChannel {
  OVO = 'OVO',
  FLEET = 'Fleet',
  OHME = 'Ohme',
  HYPERVOLT = 'HyperVolt'
}

export enum AppointmentAutocompleteStatus {
  CANCELLED = 'Cancelled',
  PENDING = 'Booked',
  ACTIVE = 'In progress',
  COMPLETED = 'Completed',
  ABORTED = 'Aborted',
  ON_ROUTE = 'Еn route'
}

export interface Complaint {
  accountNumber: string;
  severity: number | null;
  category: string | null;
  subject: string | null;
  description: string | null;
  origin: string | null;
  createdDate: string;
}

export interface Note {
  type: NoteType;
  note: string;
}

export interface AppointmentSlot {
  date: string;
  startTime: string;
  endTime: string;
}

export enum AppointmentCategory {
  CUSTOMER = 'CUSTOMER',
  MEETING = 'MEETING'
}

export enum NoteType {
  INSTALLER = 'INSTALLER',
  SCHEDULER = 'SCHEDULER',
  ADMIN = 'ADMIN'
}

export enum AppointmentReason {
  MEETING = 'Meeting',
  DRUG_ALCOHOL_TEST = 'Drug & Alcohol Test',
  TRAINING = 'Training',
  TRAVEL = 'Travel',
  LUNCH = 'Lunch'
}

export enum NewAppointmentReason {
  AdhocMentoring = 'Adhoc Mentoring',
  BelongingNetwork = 'Belonging Network',
  DevelopmentDays = 'Development Days',
  DrugAndAlcoholTest = 'Drug and Alcohol Test',
  EmpoweredPeople = 'Empowered People',
  Lunch = 'Lunch',
  Meeting = 'Meeting',
  MobilisationTravel = 'Mobilisation Travel',
  MyProgressMeeting = 'My Progress Meeting',
  OVOProjectSupport = 'OVO Project Support',
  PersonalAppointment = 'Personal Appointment',
  RoutineMentoring = 'Routine Mentoring',
  TradeUnionAdhoc = 'Trade Union - Adhoc',
  TradeUnionRegularMeeting = 'Trade Union - Regular Meeting',
  Training = 'Training',
  Travel = 'Travel',
  VehiclePlannedMaintenance = 'Vehicle - planned maintenance',
  VehicleUnplannedMaintenance = 'Vehicle - unplanned maintenance',
  VehicleSwap = 'Vehicle Swap'
}

export const OldAppointmentReasonToNewAppointmentReasonMap: Record<
  string,
  string
> = {
  [AppointmentReason.DRUG_ALCOHOL_TEST]:
    NewAppointmentReason.DrugAndAlcoholTest,
  [AppointmentReason.MEETING]: NewAppointmentReason.Meeting,
  [AppointmentReason.TRAINING]: NewAppointmentReason.Training,
  [AppointmentReason.TRAVEL]: NewAppointmentReason.Travel,
  [AppointmentReason.LUNCH]: NewAppointmentReason.Lunch
};

export enum FuelType {
  DUAL = 'DUAL',
  ELEC = 'ELEC',
  GAS = 'GAS'
}

export enum MeterType {
  ELEC = 'ELEC',
  GAS = 'GAS'
}

export enum PaymentType {
  PAYG = 'PAYG',
  PAYM = 'PAYM'
}

interface CreateCustomerAppointmentRequestCommonFields {
  category: AppointmentCategory.CUSTOMER;
  jobType: string;
  appointmentType: object;
  bookingChannel?: BookingChannel.AEGIS;
  address: {
    addressLineOne: string;
    postcode: string;
    city: string;
    addressLineTwo: string | null;
    addressLineThree: string | null;
  };
  customerDetails: {
    title?: string;
    firstName: string;
    lastName: string;
    primaryPhoneNumber: string;
    secondaryPhoneNumber: string | null;
    vulnerability: CustomerVulnerability | {};
  };
  slot: {
    startTime: string;
    endTime: string;
    date: string;
  };
  notes: {
    note: string;
    type: NoteType;
  }[];
}
export interface CreateCustomerAppointmentRequestSmart
  extends CreateCustomerAppointmentRequestCommonFields {
  brand: Brand;
  appointmentType: {
    type: CustomerAppointmentType;
    fuelType: FuelType;
    paymentType: PaymentType;
    details: {
      mprn: string;
      mpan: string;
      meterTechnicalDetails: {
        oldGasMsn: string;
        oldElecMsn: string;
      };
    };
  };
  accountNumber: string;
}

export interface CreateCustomerAppointmentRequestEV
  extends CreateCustomerAppointmentRequestCommonFields {
  appointmentType: {
    type: CustomerAppointmentType;
    details: {
      channel: EVFormChannel;
      jumptechProjectId?: string;
      zohoAccountId?: string;
      mpan?: string;
    };
  };
}

export interface CreateJobFormValues {
  appointmentType?: string;
  fuelType?: FuelType;
  date: DateTime;
  timeSlot: string;
  addressLine1: string;
  addressLine2?: string;
  addressLine3?: string;
  city: string;
  postcode: string;
  mpan: string;
  mprn: string;
  electricityMsn: string;
  gasMsn: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  accountNumber: string;
  zohoAccountId?: string;
  channel?: EVFormChannel;
  jumptechURL?: string;
  secondaryNumber?: string;
  brand: Brand;
  paymentType: PaymentType;
  bookingNotes?: string;
  jobType: {
    id: string;
    name: string;
  };
  customerHasVulnerabilities?: string;
}

export interface AbortCustomerAppointmentRequest {
  categoryId: string;
  reasonId: string;
  rebook: boolean;
  comments?: string;
}

export interface AppointmentCanCancelResponse {
  canBeCancelled: boolean;
  canBeCancelledUntil: string;
  canBeAborted: boolean;
}

export interface AppointmentIdRequest {
  ids: string[];
}

export interface PsrResponse {
  items: AppointmentPsrDetails[];
}

export interface AppointmentPsrDetails {
  appointmentId: string;
  vulnerabilities: PsrVulnerabilities | null;
}

export interface PsrVulnerabilities {
  nebuliserOrApnoeaMonitor?: boolean;
  heartLungOrVentilator?: boolean;
  dialysisFeedingPumpOrAutomatedMedicine?: boolean;
  oxygenConcentrator?: boolean;
  blind?: boolean;
  partiallySighted?: boolean;
  hearingImpairment?: boolean;
  speechImpairment?: boolean;
  stairLiftOrHoistOrElectricBed?: boolean;
  pensionableAge?: boolean;
  physicalImpairment?: boolean;
  alternativeLanguage?: PsrAlternativeLanguage | null;
  developmentalCondition?: boolean;
  unableToAnswerDoorOrRestrictedMovement?: boolean;
  dementia?: boolean;
  chronicOrSeriousIllness?: boolean;
  mdeElectricShowering?: boolean;
  carelineTelecareSystem?: boolean;
  medicineRefrigeration?: boolean;
  oxygenUse?: boolean;
  poorSenseOfSmell?: boolean;
  restrictedHandMovement?: boolean;
  mentalHealth?: boolean;
  additionalPresencePreferred?: boolean;
  waterDependency?: boolean;
  familiesWithChildren5OrUnder?: string | null;
  temporaryLifeChanges?: string | null;
  temporaryPostHospitalRecovery?: string | null;
  temporaryYoungAdultHouseholder?: string | null;
  autismSpectrum?: boolean;
  learningDifficulties?: boolean;
  dyslexia?: boolean;
  financialVulnerability?: boolean;
  anxietyDepression?: boolean;
  endOfLifeCare?: boolean;
}

type PsrAlternativeLanguage =
  | 'Acholi'
  | 'Afrikaans'
  | 'Akan'
  | 'Albanian'
  | 'Amharic'
  | 'Anuak'
  | 'Arabic'
  | 'Armenian'
  | 'Assyrian'
  | 'Azerbaijani'
  | 'Bahasa'
  | 'Bahdini'
  | 'Bajuni'
  | 'Bambara'
  | 'Bantu'
  | 'Barese'
  | 'Belorussian'
  | 'Bengali'
  | 'Berber'
  | 'Bosnian'
  | 'Bravanese'
  | 'British Sign Language'
  | 'Bulgarian'
  | 'Burmese'
  | 'Cantonese'
  | 'Catalan'
  | 'Cebuano'
  | 'Chaldean'
  | 'Chamorro'
  | 'Chaochow'
  | 'Chin Falam'
  | 'Chin Hakha'
  | 'Chin Mara'
  | 'Chin Matu'
  | 'Chin Senthang'
  | 'Chin Tedim'
  | 'Chuukese'
  | 'Cree'
  | 'Croatian'
  | 'Czech'
  | 'Danish'
  | 'Dari'
  | 'Dinka'
  | 'Dutch'
  | 'Ekegusii'
  | 'Edo'
  | 'Ewe'
  | 'Estonian'
  | 'Farsi'
  | 'Finnish'
  | 'Flemish'
  | 'French'
  | 'French Canadian'
  | 'Fukienese'
  | 'Fulani'
  | 'Fuzhou'
  | 'Ga'
  | 'Gaelic'
  | 'Gaelic-Irish'
  | 'Gaelic-Scottish'
  | 'Garre'
  | 'Georgian'
  | 'German'
  | 'Gheg'
  | 'Greek'
  | 'Gujarati'
  | 'Haitian Creole'
  | 'Hakka-China'
  | 'Hakka-Taiwan'
  | 'Hausa'
  | 'Hebrew'
  | 'Hindi'
  | 'Hindko'
  | 'Hmong'
  | 'Hunanese'
  | 'Hungarian'
  | 'Ibanag'
  | 'Igbo'
  | 'Ilocano'
  | 'Indonesian'
  | 'Italian'
  | 'Jamaican Patois'
  | 'Japanese'
  | 'Javanese'
  | 'Jingpho'
  | 'Juba Arabic'
  | 'Jula'
  | 'Kamba'
  | 'Kam Muang'
  | 'Kanjobal'
  | 'Karen'
  | 'Kayah'
  | 'Khmer'
  | 'K’iché'
  | 'Kikuyu'
  | 'Krio'
  | 'Korean'
  | 'Kurdish'
  | 'Kurmanji'
  | 'Laotian'
  | 'Latvian'
  | 'Lingala'
  | 'Lithuanian'
  | 'Luba-Kasai'
  | 'Luganda'
  | 'Luo'
  | 'Maay'
  | 'Macedonian'
  | 'Malay'
  | 'Malayalam'
  | 'Maltese'
  | 'Mam'
  | 'Mandarin'
  | 'Mandinka'
  | 'Manobo'
  | 'Marathi'
  | 'Marshallese'
  | 'Mien'
  | 'Mirpuri'
  | 'Mixteco'
  | 'Mizo'
  | 'Mongolian'
  | 'Moroccan Arabic'
  | 'Napoletano'
  | 'Navajo'
  | 'Nepali'
  | 'Nigerian Pidgin'
  | 'Norwegian'
  | 'Nuer'
  | 'Nyoro'
  | 'Oromo'
  | 'Papiamento'
  | 'Pashto'
  | 'Persian'
  | 'Plautdietsch'
  | 'Polish'
  | 'Portuguese'
  | 'Portuguese, Brazilian'
  | 'Portuguese, Cape Verdean'
  | 'Pugliese'
  | 'Pulaar'
  | 'Punjabi'
  | 'Putian'
  | 'Quechua'
  | 'Rohingya'
  | 'Romanian'
  | 'Rundi'
  | 'Russian'
  | 'Rwanda'
  | 'Samoan'
  | 'Sango'
  | 'Seraiki'
  | 'Serbian'
  | 'Shanghainese'
  | 'Shona'
  | 'Sichuan Yi'
  | 'Sicilian'
  | 'Sinhala'
  | 'Slovak'
  | 'Soga'
  | 'Somali'
  | 'Soninke'
  | 'Sorani'
  | 'Spanish'
  | 'Sudanese Arabic'
  | 'Susu'
  | 'Swahili'
  | 'Swedish'
  | 'Sylhetti'
  | 'Tagalog'
  | 'Taiwanese'
  | 'Tajik'
  | 'Tamil'
  | 'Telugu'
  | 'Thai'
  | 'Tibetan'
  | 'Tigré'
  | 'Tigrigna'
  | 'Tigrinya'
  | 'Toishanese'
  | 'Tongan'
  | 'Tooro'
  | 'Turkish'
  | 'Turkmen'
  | 'Twi'
  | 'Ukrainian'
  | 'Unknown'
  | 'Urdu'
  | 'Uyghur'
  | 'Uzbek'
  | 'Vietnamese'
  | 'Visayan'
  | 'Welsh'
  | 'Wolof'
  | 'Yemeni Arabic'
  | 'Yiddish'
  | 'Yoruba'
  | 'Yunnanese'
  | 'Zo'
  | '';

export const psrVulnerabilitiesHumanFriendlyTextMap: {
  [Property in keyof PsrVulnerabilities]: string;
} = {
  additionalPresencePreferred: 'More comfortable with someone around',
  alternativeLanguage: 'Preferred Language',
  anxietyDepression: 'Anxiety or depression',
  autismSpectrum: 'Autism spectrum',
  blind: 'Blind',
  carelineTelecareSystem: 'Careline or telecare system',
  chronicOrSeriousIllness: 'Chronically or seriously ill',
  dementia: 'Living with dementia, or a cognitive impairment',
  developmentalCondition: 'Living with a developmental condition',
  dialysisFeedingPumpOrAutomatedMedicine:
    'Dialysis, feeding pump or automated medication',
  dyslexia: 'Dyslexia',
  endOfLifeCare: 'End of life care',
  familiesWithChildren5OrUnder: 'Living with children under 5',
  financialVulnerability: 'Financial vulnerability',
  hearingImpairment: 'Hard of hearing',
  heartLungOrVentilator: 'Heart or lung ventilator',
  learningDifficulties: 'Learning difficulties',
  mdeElectricShowering: 'Medically dependent showering or bathing',
  medicineRefrigeration: 'Medicine refrigeration',
  mentalHealth: 'Living with a mental health condition',
  nebuliserOrApnoeaMonitor: 'Nebuliser or apnoea monitor',
  oxygenConcentrator: 'Oxygen concentrator',
  oxygenUse: 'Oxygen use (cylinders)',
  partiallySighted: 'Partially sighted',
  pensionableAge: 'Of pensionable age (65+)',
  physicalImpairment: 'Living with a disability',
  poorSenseOfSmell: 'Experiencing a poor sense of taste or smell',
  restrictedHandMovement: 'Restricted hand movement',
  speechImpairment: 'Living with a speech impairment',
  stairLiftOrHoistOrElectricBed: 'Stair lift, hoist, electric bed',
  temporaryLifeChanges: 'Experiencing difficulty due to life changing events',
  temporaryPostHospitalRecovery: 'In post-hospital recovery after a surgery',
  temporaryYoungAdultHouseholder: 'A young adult householder (under 18)',
  unableToAnswerDoorOrRestrictedMovement: 'Unable to answer the door',
  waterDependency: 'Water-dependent due to a medical condition'
};
